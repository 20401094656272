@import '../node_modules/@syncfusion/ej2-icons/styles/bootstrap5.css';

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

a {
  text-decoration: none;
}
